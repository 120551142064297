// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import './stylesheets/application.scss'
// require('./coffeescript/application.js.coffee')
// require('./coffeescript/init.js.coffee')
// require('./coffeescript/kiosk.js.coffee')
import './coffeescript/application.js.coffee'

Rails.start()
ActiveStorage.start()
$(function(){
    window.KioskSetup.perform()
    window.ClickableRow.init();
    window.KioskApp.Orders.init();
})
