import * as SignaturePad from 'signature_pad';
class window.KioskSetup
  @perform: ->
    amount_value = $('#v2_stock_version_amount').val()
    $('#v2_stock_version_amount').change ->
      if amount_value != $(this).val()
        $('#reason_select').show(300)
      else
        $('#reason_select').hide(300)

    $('#stock_amount').focus ->
      $('#reason_select').show(300)
    $('#stock_amount').blur ->
      if amount_value == $(this).val()
        $('#reason_select').hide(300)
    if $('.sigPad').length > 0
      signaturePad = new SignaturePad.default($('.sigPad .pad')[0], {drawOnly:true,onEnd: ->
        $('#invoice_signature, #invoice_client_signature').val signaturePad.toDataURL()
      })
      $('.sigPad .clearButton a').click ->
        signaturePad.clear()


      $('.sigPad').submit (e) ->
        $('#invoice_signature, #invoice_client_signature').val signaturePad.toDataURL()

    setInterval (->
      if $("#invoice_camp_boss_name, #invoice_client_name").length>0 && $("#invoice_camp_boss_name, #invoice_client_name").val().length > 0 && $('#invoice_signature, #invoice_client_signature').val().length > 0
        $(".sigPad input[type='submit']").removeAttr('disabled')
      else
        $(".sigPad input[type='submit']").attr('disabled', 'disabled')
    ), 1500
