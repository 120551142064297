window.KioskApp ||= {}

class window.KioskApp.OrderValidator
  constructor: (@element) ->

  validateIntegerInput: ->
    if @.isInteger($(@element).val())
      @.hideRowErrors()
    else
      $(@element).parents("tr").find("input").addClass("error")
      @.showRowErrors()

  isInteger: (value) ->
    Math.floor(value) == +value && $.isNumeric(value)

  validateTotals: ->
    total = @.itemsSum()
    ordered = parseInt($(@element).parents("tr").find("[data-ordered-count]").first().html())

    if ordered!=total
      @.showRowErrors()
      $("input[type=submit]").prop("disabled",true)
    else
      @.hideRowErrors()
      $("input[type=submit]").prop("disabled",false)

  itemsSum: ->
    total = 0
    $(@element).parents("tr").find("input").each ->
      total += parseInt($(this).val())
    total

  showRowErrors: ->
    $(@element).parents("tr").find("input").addClass("error")
    $(".alert-danger").removeClass("d-none")

  hideRowErrors: ->
    $(@element).parents("tr").find("input").removeClass("error")
    if $("input.error").length == 0
      $(".alert-danger").addClass("d-none")

class window.KioskApp.TotalUpdator
  contructor: () ->


  updateSum: ->
    total_sum = $("#items").next().find(".total-sum")
    table = $("#items .table tbody")
    sum = 0
    thiss = @
    $(table).find("tr").each(()->
      row = $(this)
      rowSum = thiss.retailPrice(row) * (thiss.received(row) + thiss.delivered_later(row))
      rowSum = 0 if isNaN(rowSum)
      sum += rowSum
    )
    $(total_sum).text("DKK " + sum.toFixed(2))

  retailPrice: (row) ->
    parseFloat($(row).find(".retail_price").text())

  received: (row) ->
    parseInt($(row).find(".received input").val())

  delivered_later: (row) ->
    parseInt($(row).find(".delivered_later input").val())

class window.KioskApp.Orders
  @init: ->
    toggleOrderButtons = ->
      $("#show-order").toggleClass('d-none')
      $(".alert-info").toggleClass('d-none')
      $("#submit-order").toggleClass('d-none')
      $("#all-order-lines").toggleClass('d-none')

    $("#show-order").click ->
      if $("input.error").length == 0
        $("input.numeric").each ->
          if($(this).val()=="0")
            $(this).parents("tr").addClass('d-none')
        toggleOrderButtons()

    $("#all-order-lines").click ->
      $("input.numeric").each ->
        if($(this).val()=="0")
          $(this).parents("tr").removeClass('d-none')
      toggleOrderButtons()

    $("#submit-order").click ->
      if $("input.error").length == 0
        $("form.new_order").submit()

    $(".orders.new input").on("change", ->
      orderValidator = new window.KioskApp.OrderValidator(this)
      orderValidator.validateIntegerInput()
    )

    if $(".orders.edit").length > 0
      totalUpdator = new KioskApp.TotalUpdator()
      totalUpdator.updateSum()

    $(".orders.edit input").on("change", ->
      orderValidator = new window.KioskApp.OrderValidator(this)
      orderValidator.validateTotals()
      totalUpdator = new KioskApp.TotalUpdator()
      totalUpdator.updateSum()
    )